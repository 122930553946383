import {authRole, AuthType} from './AppEnums';

export const defaultUser = {
  displayName: 'John Alex',
  email: 'demo@example.com',
  authType: AuthType.JWT_AUTH,
  token: 'access-token',
  role: authRole.admin,
  photoURL: '/assets/images/avatar/A11.jpg',
};
//COMPANY_DURATION (type of time_frame input)
export const applicationTimeFrame = {
  label: 'Week',
  value: 'WEEK',
};
//OPTIONS_FOR_PRODUCT_COST
export const productCostTypeOptions = [
  {label: 'Delay', value: 'delay', id: 'delay'},
  {label: 'Not Doing', value: 'notDoing', id: 'notdoing'},
];
export const booleanOptions = [
  {label: 'Yes', value: true, id: 'addressable-yes'},
  {label: 'No', value: false, id: 'addressable-no'},
];

//COMPANY_DURATION
export const durationOptions = [
  {label: 'Seconds', value: 'SECOND', id: 'second'},
  {label: 'Minutes', value: 'MINUTE', id: 'minute'},
  {label: 'Hours', value: 'HOUR', id: 'hour'},
  {label: 'Weeks', value: 'WEEK', id: 'week'},
  {label: 'Months', value: 'MONTH', id: 'month'},
  {label: 'Years', value: 'YEAR', id: 'year'},
];

export const targetValues = [
  {label: 'Yes', value: 'YES', id: 'yes'},
  {label: 'No', value: 'NO', id: 'no'},
  {label: 'Not yet ', value: 'NOT_YET', id: 'not_yet'},
];
//

export const TabsLabelStatic = {
  product: 'Product Goal',
  market: 'Know your Market',
  customer: 'Who is the customer',
  whatCustomerWants: 'What Customer Wants',
  salesProjection: 'Sales Projection',
  marketingStrategy: 'Marketing Strategy',
  salesStrategy: 'Sales Strategy',
  engineeringEvaluation: 'Engineering Evaluation',
  activityExecutionStrategy: 'Activity Based Execution',
  featureExecutionStrategy: 'Feature Based Execution',
  maintenance: 'Maintenance',
  financial: 'Financial Evaluation',
  cashFlow: 'Cash Flow',
};

//OPTIONS_FOR_FEATURE_COMPETITOR
export const featureCompetitor = [
  {
    label: 'Strong',
    value: 'Strong',
    id: 'strong',
  },
  {
    label: 'Moderate',
    value: 'Moderate',
    id: 'moderate',
  },
  {
    label: 'Low',
    value: 'Low',
    id: 'low',
  },
  {
    label: 'Not Present',
    value: 'Not Present',
    id: 'not-present',
  },
];
//URL
export const IMAGE_URL = 'https://api-dev.truemoso.com/images/';
export const initialUrl = '/home/create_company'; // this url will open after login
export const subscriptionUrl = '/home/packages'; //after login when the role is IS_BILLING_STARTED

export const Action = {
  View: 'view',
  Create: 'create',
  Edit: 'edit',
  Delete: 'delete',
};

export const ModuleNames = {
  Cases: 'Cases',
  CasesProductGoals: 'Cases.ProductGoals',
  CasesProductGoalsCostOfNotDoing: 'Cases.ProductGoals.CostOfNotDoing',
  CasesProductGoalsCostOfDelay: 'Cases.ProductGoals.CostOfDelay',
  CasesProductGoalsBusinessBenefits: 'Cases.ProductGoals.BusinessBenefits',
  CasesProductGoalsProductImage: 'Cases.ProductGoals.ProductImage',
  CasesKnowYourMarket: 'Cases.KnowYourMarket',
  CasesKnowYourMarketRisk: 'Cases.KnowYourMarket.Risk',
  CasesKnowYourMarketOpportunity: 'Cases.KnowYourMarket.Opportunity',
  CasesWhoIsTheCustomer: 'Cases.WhoIsTheCustomer',
  CasesWhoIsTheCustomerTargetMarketSegment:
    'Cases.WhoIsTheCustomer.TargetMarketSegment',
  CasesWhoIsTheCustomerPositioning: 'Cases.WhoIsTheCustomer.Positioning',
  CasesWhoIsTheCustomerCustomerProfileAndCompetition:
    'Cases.WhoIsTheCustomer.CustomerProfileAndCompetition',
  CasesWhatCustomerWants: 'Cases.WhatCustomerWants',
  CasesSalesProjection: 'Cases.SalesProjection',
  CasesSalesProjectionProductLifeTime: 'Cases.SalesProjection.ProductLifeTime',
  CasesSalesProjectionLifeTimeVolume: 'Cases.SalesProjection.LifeTimeVolume',
  CasesSalesProjectionLifeTimeRevenue: 'Cases.SalesProjection.LifeTimeRevenue',
  CasesSalesProjectionYear: 'Cases.SalesProjection.Year',
  CasesSalesProjectionSegment: 'Cases.SalesProjection.Segment',
  CasesSalesProjectionProjectionUnits: 'Cases.SalesProjection.ProjectionUnits',
  CasesSalesProjectionPricePerUnits: 'Cases.SalesProjection.PricePerUnits',
  CasesSalesProjectionTotalUnitsPerYear:
    'Cases.SalesProjection.TotalUnitsPerYear',
  CasesSalesProjectionTotalRevenuePerYear:
    'Cases.SalesProjection.TotalRevenuePerYear',
  CasesMarketingStrategy: 'Cases.MarketingStrategy',
  CasesMarketingStrategyOutcomes: 'Cases.MarketingStrategy.Outcomes',
  CasesMarketingStrategyManpower: 'Cases.MarketingStrategy.Manpower',
  CasesMarketingStrategyResourceExceptManpower:
    'Cases.MarketingStrategy.ResourceExceptManpower',
  CasesMarketingStrategyOneTimeCostOfResource:
    'Cases.MarketingStrategy.OneTimeCostOfResource',
  CasesMarketingStrategyRecurringTimeCostOfResource:
    'Cases.MarketingStrategy.RecurringTimeCostOfResource',
  CasesMarketingStrategyRecurrenceBasedOn:
    'Cases.MarketingStrategy.RecurrenceBasedOn',
  CasesSalesStrategy: 'Cases.SalesStrategy',
  CasesSalesStrategyOutcomes: 'Cases.SalesStrategy.Outcomes',
  CasesSalesStrategyManpower: 'Cases.SalesStrategy.Manpower',
  CasesSalesStrategyResourceExceptManpower:
    'Cases.SalesStrategy.ResourceExceptManpower',
  CasesSalesStrategyOneTimeCostOfResource:
    'Cases.SalesStrategy.OneTimeCostOfResource',
  CasesSalesStrategyRecurringTimeCostOfResource:
    'Cases.SalesStrategy.RecurringTimeCostOfResource',
  CasesSalesStrategyRecurrenceBasedOn: 'Cases.SalesStrategy.RecurrenceBasedOn',
  CasesEngineeringEvaluation: 'Cases.EngineeringEvaluation',
  CasesExecutionBasedFields: 'Cases.ExecutionBasedFields',
  CasesExecutionBasedFieldsManpower: 'Cases.ExecutionBasedFields.Manpower',
  CasesExecutionBasedFieldsResourceExceptManpower:
    'Cases.ExecutionBasedFields.ResourceExceptManpower',
  CasesExecutionBasedFieldsOneTimeCostOfResource:
    'Cases.ExecutionBasedFields.OneTimeCostOfResource',
  CasesExecutionBasedFieldsRecurringTimeCostOfResource:
    'Cases.ExecutionBasedFields.RecurringTimeCostOfResource',
  CasesExecutionBasedFieldsRecurrenceBasedOn:
    'Cases.ExecutionBasedFields.RecurrenceBasedOn',
  CasesMaintenance: 'Cases.Maintenance',
  CasesMaintenanceWarrantyPeriod: 'Cases.Maintenance.WarrantyPeriod',
  CasesMaintenanceType: 'Cases.Maintenance.Type',
  CasesMaintenanceYears: 'Cases.Maintenance.Years',
  CasesFinancialEvaluation: 'Cases.FinancialEvaluation',
  CasesFinancialEvaluationCOGS: 'Cases.FinancialEvaluation.COGS',
  CasesFinancialEvaluationCostOfCapital:
    'Cases.FinancialEvaluation.CostOfCapital',
  CasesFinancialEvaluationQuantity: 'Cases.FinancialEvaluation.Quantity',
  CasesFinancialEvaluationName: 'Cases.FinancialEvaluation.Name',
  CasesFinancialEvaluationLineCost: 'Cases.FinancialEvaluation.LineCost',
};

export const modulesData = {
  moduleName: 'Cases',
  accessRights: {
    view: true,
    create: true,
    edit: true,
    delete: true,
  },
  subModules: [
    {
      moduleName: 'Cases.ProductGoals',
      accessRights: {
        view: true,
      },
      subModules: [
        {
          moduleName: 'Cases.ProductGoals.BusinessBenefits',
          accessRights: {
            view: true,
            create: false,
            edit: true,
            delete: false,
          },
        },
        {
          moduleName: 'Cases.ProductGoals.ProductImage',
          accessRights: {
            view: true,
          },
        },
        {
          moduleName: 'Cases.ProductGoals.CostOfDelay',
          accessRights: {
            view: true,
            create: false,
            edit: true,
            delete: true,
          },
        },
        {
          moduleName: 'Cases.ProductGoals.CostOfNotDoing',
          accessRights: {
            view: true,
            create: true,
            edit: true,
            delete: true,
          },
        },
      ],
    },
    {
      moduleName: 'Cases.KnowYourMarket',
      accessRights: {
        view: true,
        create: true,
        edit: true,
        delete: true,
      },
      subModules: [
        {
          moduleName: 'Cases.KnowYourMarket.Risk',
          accessRights: {
            view: true,
            create: true,
            edit: true,
            delete: true,
          },
        },
        {
          moduleName: 'Cases.KnowYourMarket.Opportunity',
          accessRights: {
            view: true,
            create: true,
            edit: true,
            delete: true,
          },
        },
      ],
    },
    {
      moduleName: 'Cases.WhoIsTheCustomer',
      accessRights: {
        view: true,
      },
      subModules: [
        {
          moduleName: 'Cases.WhoIsTheCustomer.Positioning',
          accessRights: {
            view: true,
            edit: true,
          },
        },
        {
          moduleName: 'Cases.WhoIsTheCustomer.CustomerProfileAndCompetition',
          accessRights: {
            view: true,
          },
        },
      ],
    },
    {
      moduleName: 'Cases.WhatCustomerWants',
      accessRights: {
        view: true,
        create: true,
        edit: true,
        delete: true,
      },
    },
    {
      moduleName: 'Cases.SalesProjection',
      accessRights: {
        view: true,
        create: true,
        delete: true,
      },
      subModules: [
        {
          moduleName: 'Cases.SalesProjection.ProductLifeTime',
          accessRights: {
            view: true,
            edit: true,
          },
        },
        {
          moduleName: 'Cases.SalesProjection.LifeTimeVolume',
          accessRights: {
            view: true,
          },
        },
        {
          moduleName: 'Cases.SalesProjection.LifeTimeRevenue',
          accessRights: {
            view: true,
          },
        },
        {
          moduleName: 'Cases.SalesProjection.Year',
          accessRights: {
            view: true,
          },
        },
        {
          moduleName: 'Cases.SalesProjection.Segment',
          accessRights: {
            view: true,
          },
        },
        {
          moduleName: 'Cases.SalesProjection.ProjectionUnits',
          accessRights: {
            view: true,
            edit: true,
          },
        },
        {
          moduleName: 'Cases.SalesProjection.PricePerUnits',
          accessRights: {
            view: true,
            edit: true,
          },
        },
        {
          moduleName: 'Cases.SalesProjection.TotalUnitsPerYear',
          accessRights: {
            view: true,
          },
        },
        {
          moduleName: 'Cases.SalesProjection.TotalRevenuePerYear',
          accessRights: {
            view: true,
          },
        },
      ],
    },
    {
      moduleName: 'Cases.MarketingStrategy',
      accessRights: {
        view: true,
        create: true,
        edit: true,
        delete: true,
      },
      subModules: [
        {
          moduleName: 'Cases.MarketingStrategy.Outcomes',
          accessRights: {
            view: true,
            edit: true,
          },
        },

        {
          moduleName: 'Cases.MarketingStrategy.ResourceExceptManpower',
          accessRights: {
            view: true,
            edit: true,
          },
        },
        {
          moduleName: 'Cases.MarketingStrategy.OneTimeCostOfResource',
          accessRights: {
            view: true,
            edit: true,
          },
        },
        {
          moduleName: 'Cases.MarketingStrategy.RecurringTimeCostOfResource',
          accessRights: {
            view: true,
            edit: true,
          },
        },
        {
          moduleName: 'Cases.MarketingStrategy.RecurrenceBasedOn',
          accessRights: {
            view: true,
            edit: true,
          },
        },
      ],
    },
    {
      moduleName: 'Cases.SalesStrategy',
      accessRights: {
        view: true,
        create: true,
        edit: true,
        delete: true,
      },
      subModules: [
        {
          moduleName: 'Cases.SalesStrategy.Outcomes',
          accessRights: {
            view: true,
            edit: true,
          },
        },
        {
          moduleName: 'Cases.SalesStrategy.Manpower',
          accessRights: {
            view: true,
            edit: true,
          },
        },
        {
          moduleName: 'Cases.SalesStrategy.ResourceExceptManpower',
          accessRights: {
            view: true,
            edit: true,
          },
        },
        {
          moduleName: 'Cases.SalesStrategy.OneTimeCostOfResource',
          accessRights: {
            view: true,
            edit: true,
          },
        },
        {
          moduleName: 'Cases.SalesStrategy.RecurringTimeCostOfResource',
          accessRights: {
            view: true,
            edit: true,
          },
        },
        {
          moduleName: 'Cases.SalesStrategy.RecurrenceBasedOn',
          accessRights: {
            view: true,
            edit: true,
          },
        },
      ],
    },
    {
      moduleName: 'Cases.EngineeringEvaluation',
      accessRights: {
        view: true,
      },
    },
    {
      moduleName: 'Cases.ExecutionBasedFields',
      accessRights: {
        view: true,
        create: true,
        delete: true,
      },
      subModules: [
        {
          moduleName: 'Cases.ExecutionBasedFields.ResourceExceptManpower',
          accessRights: {
            view: true,
            edit: true,
          },
        },
        {
          moduleName: 'Cases.ExecutionBasedFields.OneTimeCostOfResource',
          accessRights: {
            view: true,
            edit: true,
          },
        },
        {
          moduleName: 'Cases.ExecutionBasedFields.RecurringTimeCostOfResource',
          accessRights: {
            view: true,
            edit: true,
          },
        },
        {
          moduleName: 'Cases.ExecutionBasedFields.RecurrenceBasedOn',
          accessRights: {
            view: true,
            edit: true,
          },
        },
      ],
    },
    {
      moduleName: 'Cases.Maintenance',
      accessRights: {
        view: true,
      },
      subModules: [
        {
          moduleName: 'Cases.Maintenance.Years',
          accessRights: {
            view: true,
            edit: true,
          },
        },
      ],
    },
    {
      moduleName: 'Cases.FinancialEvaluation',
      accessRights: {
        view: true,
        create: true,
      },
    },
  ],
};
