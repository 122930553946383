import {
  CANCEL_SUBSCRIPTION_OF_COMPANY_ADMIN,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_BILLING_INFORMATION_FOR_COMPANY,
  GET_USER_LIST_FOR_COMPANY,
  UPDATE_USER_ROLE,
  // SHOW_MESSAGE,
} from '../../shared/constants/ActionTypes';
import {appIntl} from '@crema/utility/helper/Utils';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export const getBillingInfoByCompanyId = (user, setTimeOut) => {
  const companyId = localStorage.getItem('companyId');
  const {messages} = appIntl();
  // const errorInfo = () => {
  //   setTimeout(() => {

  //   }, 2000);
  // }
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(
        `frontend/stripe/subscriptions/${user?.id}/${companyId}`,
      );
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: GET_BILLING_INFORMATION_FOR_COMPANY,
          payload: data?.data,
        });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      switch (error?.response?.status) {
        case 404:
          dispatch({
            type: FETCH_ERROR,
            payload: 'You have no subscription,redirecting you to Home Page.',
          });
          setTimeOut && setTimeOut();
          break;
        default:
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went Wrong, Please try again.',
          });
          break;
      }
    }
  };
};

export const getCompanyUserForUserList = () => {
  const companyId = localStorage.getItem('companyId');
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(
        `users/company/AllUsersWithAdmin/${companyId}`,
      );
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_USER_LIST_FOR_COMPANY, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = 'Something went wrong, Please try again!';
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const editUserAdminRole = (role, userEmail) => {
  const companyId = localStorage.getItem('companyId');
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(`users/editUserRole`, {
        role,
        userEmail,
        companyId,
      });
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: UPDATE_USER_ROLE, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = 'Something went wrong, Please try again!';
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const cancelSubscriptionByUserId = (userId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.put(
        `frontend/stripe/subscriptions/cancelSubscription/${userId}`,
      );

      if (data.status === 200) {
        dispatch({
          type: FETCH_ERROR,
          payload: `Your subscription has been cancelled`,
        });
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: CANCEL_SUBSCRIPTION_OF_COMPANY_ADMIN,
          payload: data.data,
        });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = 'Something went wrong, Please try again!';
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const sendPaymentConfirmationEmailToAdminUser = (userId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.put(
        `frontend/stripe/users/sendPaymentConfirmationEmail/${userId}`,
      );

      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = (errorMessage = error?.response?.data?.errorMessage
        ? (errorMessage = error?.response?.data?.errorMessage)
        : 'Something went wrong, Please try again!');
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
