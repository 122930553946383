import {
  GET_COMPANY_DATA,
  UPDATE_COMPANY_DATA,
  CREATE_COMPANY_DATA,
  GET_COMPANY_USER_FOR_COMPANY,
  UPDATED_COMPANY_APPROVED_COUNT,
  CREATE_BUSINESS_UNITS_DATA,
  UPDATE_BUSINESS_UNITS_DATA,
  DELETE_BUSINESS_UNITS_DATA,
  GET_BUSINESS_UNITS_DATA,
  GET_APPROVED_CASE_BY_COMPANY_ID,
  GET_APPROVED_COMPANY_DATA,
  UPDATE_CASE_STATUS,
  DELETE_CASE,
  GET_METADATA,
} from '../../shared/constants/ActionTypes';

const initialState = {
  companyDetails: false,
  managementDetails: false,
  businessUnitsDetails: false,
  approvedCaseData: false,
  companyApprovedCaseInfo: false,
  metadata: false,
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_DATA:
      return {
        ...state,
        companyDetails: action.payload,
      };
    case UPDATE_COMPANY_DATA:
      return {
        ...state,
        companyDetails: action.payload,
      };
    case CREATE_COMPANY_DATA:
      return {
        ...state,
        companyDetails: action.payload,
      };
    case GET_COMPANY_USER_FOR_COMPANY:
      return {
        ...state,
        managementDetails: action.payload,
      };
    case UPDATED_COMPANY_APPROVED_COUNT:
      return {
        ...state,
        companyDetails: false,
      };
    case GET_BUSINESS_UNITS_DATA:
      return {
        ...state,
        businessUnitsDetails: action.payload,
      };
    case GET_APPROVED_COMPANY_DATA:
      return {
        ...state,
        companyApprovedCaseInfo: action.payload,
      };
    case GET_APPROVED_CASE_BY_COMPANY_ID:
      return {
        ...state,
        approvedCaseData: action.payload,
      };
    case UPDATE_BUSINESS_UNITS_DATA:
      return {
        ...state,
        businessUnitsDetails: false,
      };
    case UPDATE_CASE_STATUS:
      return {
        ...state,
        approvedCaseData: false,
      };
    case DELETE_CASE:
      return {
        ...state,
        approvedCaseData: false,
      };
    case CREATE_BUSINESS_UNITS_DATA:
      return {
        ...state,
        businessUnitsDetails: false,
      };
    case DELETE_BUSINESS_UNITS_DATA:
      return {
        ...state,
        businessUnitsDetails: false,
      };
    case GET_METADATA: {
      return {
        ...state,
        metadata: action.payload,
      };
    }
    default:
      return state;
  }
};

export default companyReducer;
