import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_INVITED_MAIL,
  MAIL_INVITE_EXPIRE,
  INTERNAL_SERVER,
  GET_COMPANY_INVITE,
  INVITE_COMPANY_USER,
  DELETE_INVITED_COMPANY_USER,
  SHOW_MESSAGE,
} from '../../shared/constants/ActionTypes';
import {appIntl} from '@crema/utility/helper/Utils';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';
import {getCompanyUserForUserList} from './billingInfo';

export const getInvitedCustomer = (token) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`invitedUsers/${token}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_INVITED_MAIL, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      if (error?.response?.status === 410) {
        dispatch({type: MAIL_INVITE_EXPIRE});
      }
      if (error?.response?.status === 500) {
        dispatch({type: INTERNAL_SERVER});
      }
    }
  };
};

export const getInvitedUserByCompanyId = (companyId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`invitedUsers/company/${companyId}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_COMPANY_INVITE, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = 'Something went wrong, Please try again!';
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const inviteCompanyUser = (userData) => {
  const companyId = localStorage.getItem('companyId');

  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.post(`invitedUsers`, userData);
      if (data.status === 200) {
        const successMessage =
          'invite link successfully send to your email address';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: INVITE_COMPANY_USER, payload: data.data});
        dispatch(getCompanyUserForUserList(companyId));
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 400:
          errorMessage = 'Email already exists';
          break;
        default:
          errorMessage = 'Something went wrong, Please try again! ';
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const reInviteCompanyUser = (userData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.post(`invitedUsers/resendInvite`, userData);
      if (data.status === 200) {
        const successMessage =
          'invite link successfully send to your email address';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: INVITE_COMPANY_USER, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 400:
          errorMessage = 'Email already exists';
          break;
        case 404:
          errorMessage = `User with given email doesn't invited in given company.`;
          break;
        default:
          errorMessage = 'Something went wrong, Please try again! ';
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const deleteInvitedCompanyUser = (userId) => {
  const {messages} = appIntl();
  const companyId = localStorage.getItem('companyId');

  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.delete(
        `invitedUsers/company/${userId}/${companyId}`,
      );
      if (data.status === 200) {
        const successMessage = 'User successfully deleted';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DELETE_INVITED_COMPANY_USER, payload: data.data});
        dispatch(getCompanyUserForUserList(companyId));
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = 'Something went wrong, Please try again!';
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
