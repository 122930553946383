import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_COMPANY_CASES,
  GET_COMPANY_USER_FOR_CASE,
  CREATE_CASE,
  GET_CASE_DETAILS,
  UPDATE_CASE_DETAILS,
  ADD_CASE_USER,
  UPDATE_CASE_USER,
  DELETE_CASE_USER,
  GET_CASE_USER,
  UPDATE_CASE_STATUS,
  SHOW_MESSAGE,
  UPDATED_COMPANY_APPROVED_COUNT,
  UPDATE_CASE_DEFAULT_VALUE,
  GET_COMPANY_USER,
  CREATE_TEST_CASE,
  CREATE_DUPLICATE_CASE,
  DELETE_CASE,
} from '../../shared/constants/ActionTypes';
import {appIntl} from '@crema/utility/helper/Utils';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export const getCompanyCases = (companyId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`cases/company/${companyId}`);

      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_COMPANY_CASES, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = 'Something went wrong, Please try again!';
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getCompanyUserForCase = (companyId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`users/company/forCase/${companyId}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_COMPANY_USER_FOR_CASE, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = 'Something went wrong, Please try again!';
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const getCompanyUserForCompanyByCompanyId = (companyId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`users/company/forCompany/${companyId}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_COMPANY_USER, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = 'Something went wrong, Please try again!';
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const getCaseDetailsByCaseId = (caseId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`cases/${caseId}`);

      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_CASE_DETAILS, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Case not found';
          break;
        default:
          errorMessage = 'Something went wrong, Please try again! ';
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
export const updateCaseDetailsByCaseId = (caseId, caseData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(`cases/${caseId}`, caseData);
      if (data.status === 200) {
        const successMessage = 'Your case successfully update';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: UPDATE_CASE_DETAILS, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Case not found';
          break;
        default:
          errorMessage = 'Something went wrong, Please try again! ';
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateCaseDefaultValueByCaseId = (caseId, caseData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(
        `cases/defaultValues/${caseId}`,
        caseData,
      );
      if (data.status === 200) {
        const successMessage = 'Your case default value  successfully update';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: UPDATE_CASE_DEFAULT_VALUE, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Case not found';
          break;
        default:
          errorMessage = 'Something went wrong, Please try again! ';
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const createCase = (caseData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.post(`cases`, caseData);

      if (data.status === 200) {
        const successMessage = 'Your case successfully created';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: CREATE_CASE, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Company not found';
          break;
        default:
          errorMessage = 'Something went wrong, Please try again! ';
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

//Create Duplicate Case

export const createDuplicateCaseByCaseId = (id) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.post(`cases/${id}`);

      if (data.status === 200) {
        const successMessage = 'Your Duplicate case successfully created';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: CREATE_DUPLICATE_CASE, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Case not found';
          break;
        default:
          errorMessage = 'Something went wrong, Please try again! ';
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

//

// DELETE CASE

export const deleteCaseByCaseId = (id) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.delete(`cases/${id}`);
      if (data.status === 200) {
        const successMessage = 'Case successfully deleted';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DELETE_CASE, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = 'Something went wrong, Please try again!';
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

//

//CASE_USER_CRUD
export const getCaseUserByCaseId = (caseId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.get(`cases/user/${caseId}`);
      if (data.status === 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: GET_CASE_USER, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = 'Something went wrong, Please try again!';
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const addCaseUserByCaseId = (caseId, userData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.post(`cases/user/${caseId}`, userData);
      if (data.status === 200) {
        const successMessage = 'Case user successfully add';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: ADD_CASE_USER, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 400:
          errorMessage = 'User already exist in this case.';
          break;
        case 404:
          errorMessage = 'Case not found';
          break;
        default:
          errorMessage = 'Something went wrong, Please try again! ';
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const updateCaseUserById = (caseId, id, userData) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(`cases/user/${caseId}/${id}`, userData);
      if (data.status === 200) {
        const successMessage = 'Case user successfully update';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: UPDATE_CASE_USER, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Case or User not found ';
          break;
        default:
          errorMessage = 'Something went wrong, Please try again! ';
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const deleteCaseUserById = (id) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.delete(`cases/user/${id}`);
      if (data.status === 200) {
        const successMessage = 'Case user successfully delete';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DELETE_CASE_USER, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = 'Something went wrong, Please try again!';
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

//UPDATE_CASE_STATUS
export const updateCaseStatusByCaseId = (caseStatus, caseId, userId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.patch(
        `cases/${caseStatus}/${caseId}/${userId}`,
      );

      if (data.status === 200) {
        const successMessage = 'Case Status successfully update';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: UPDATE_CASE_STATUS, payload: data.data});
        dispatch({type: UPDATED_COMPANY_APPROVED_COUNT});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Case not found';
          break;
        default:
          errorMessage = 'Something went wrong, Please try again! ';
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};

export const createTestCaseByCompanyId = (companyId) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      let data = await jwtAxios.post(`seeders/${companyId}/1`);

      if (data.status === 200) {
        const successMessage = 'Case Status successfully update';
        dispatch({type: SHOW_MESSAGE, payload: successMessage});
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: CREATE_TEST_CASE});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      let errorMessage = '';
      switch (error?.response?.status) {
        case 404:
          errorMessage = 'Company Not Found';
          break;
        default:
          errorMessage = 'Something went wrong, Please try again! ';
          break;
      }
      dispatch({type: FETCH_ERROR, payload: errorMessage});
    }
  };
};
